import React from "react"
import tw from "twin.macro"

import InputLabel from "./InputLabel"
import { string2cmyk } from "@src/utils/color"

const ColorValues = ({
  messageColor,
}: {
  messageColor?: string
}): React.ReactElement => (
  <>
    {["C", "M", "Y", "K"].map((color, index) => (
      <div key={color} tw="flex flex-col items-center">
        <InputLabel>{color}</InputLabel>
        <div
          css={tw`text-xs text-black w-12 h-8 rounded-full border border-gray-400 flex items-center justify-center`}
        >
          {messageColor
            ? `${Math.round(string2cmyk(messageColor, true)[index])}%`
            : "-"}
        </div>
      </div>
    ))}
  </>
)

export default ColorValues
