import React from "react"
import tw from "twin.macro"

const InputLabel = ({
  isRequired = false,
  customCss = "",
  children,
}: {
  isRequired?: boolean
  customCss?: any
  children: any
}): React.ReactElement => (
  <label css={[tw`text-2xs! text-black font-bold`, customCss]}>
    {children}
    {isRequired && <span tw="text-2xs text-red-600">*</span>}
  </label>
)

export default InputLabel
