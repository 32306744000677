import React from "react"
import tw, { css } from "twin.macro"
import ContentEditable from "react-contenteditable"
import { Form, Input } from "antd"
import { CloseCircleTwoTone } from "@ant-design/icons"

import { cmyk2hex, string2cmyk } from "@src/utils/color"
import { fontWeight } from "../layout/fonts"
import { Template } from "@src/queries/template"
import { parseText } from "@src/utils/parseText"

const CloseIcon = ({ onClick }: { onClick: any }) => (
  <CloseCircleTwoTone
    twoToneColor="#000fff"
    css={[
      tw`absolute text-sm cursor-pointer text-sm`,
      css`
        right: -5px;
        top: -5px;
      `,
    ]}
    onClick={onClick}
  />
)

const EditableField = ({
  field,
  textField,
  clearField,
  template,
  editable,
  setField,
  updateFormFields,
  customCss,
}: {
  field: string | null
  textField: string
  clearField: any
  template: Template
  editable: boolean
  setField: any
  updateFormFields: any
  customCss?: any
}): React.ReactElement => {
  // content editable ref
  const editableRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    const onPaste = (event: any) => {
      if (document && event && event.clipboardData) {
        event.preventDefault()
        document.execCommand(
          "inserttext",
          false,
          event.clipboardData
            .getData("text/plain")
            .replace(/(?:\r\n|\r|\n)/g, " ")
        )
      }
    }

    if (editableRef && editableRef.current) {
      editableRef.current.addEventListener("paste", onPaste)
    }

    return () => {
      if (editableRef && editableRef.current) {
        editableRef.current.removeEventListener("paste", onPaste)
      }
    }
  }, [editableRef])

  return (
    <div css={[tw`relative w-full`]}>
      {editable && field === textField && (
        <CloseIcon onClick={() => clearField(textField)} />
      )}
      <Form.Item name={textField} css={tw`hidden!`}>
        <Input />
      </Form.Item>
      <ContentEditable
        innerRef={editableRef}
        html={parseText((template as any)[textField])}
        disabled={!editable}
        onFocus={() => setField && setField(textField)}
        onChange={e => {
          updateFormFields({
            [textField]: e.target.value,
          })
        }}
        style={{ outline: "none" }}
        css={[
          tw`text-center border`,
          customCss,
          css`
            color: ${cmyk2hex(
              string2cmyk((template as any)[`${textField}Color`], true)
            )};
            font-family: ${(template as any)[`${textField}FontFamily`]};
            font-size: ${(template as any)[`${textField}FontSize`]}pt;
            line-height: 1em;
          `,
          editable ? tw`border-gray-500` : tw`border-transparent`,
          editable && field === textField && tw`border-white!`,
          [fontWeight.bold, fontWeight.boldItalics].includes(
            (template as any)[`${textField}Variant`]
          ) && tw`font-bold`,
          [fontWeight.italics, fontWeight.boldItalics].includes(
            (template as any)[`${textField}Variant`]
          ) && tw`italic`,
          (template as any)[`${textField}IsUnderlined`] && tw`underline`,
        ]}
      />
    </div>
  )
}

export default EditableField
