import React from "react"
import tw, { css } from "twin.macro"
import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby"
import { Form, Radio } from "antd"
import { useQuery, useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"

import { Localization } from "@src/localization"
import CampaignNavigation from "@src/components/campaign/navigation"
import ActionBar from "@src/components/campaign/actionBar"
import InfoBarWithContext from "@src/components/infoBar/infoBarWithContext"
import CustomContainer from "@src/components/customContainer"
import { palettes } from "@src/sections/createCampaign/content/palettes"
import { GET_TEMPLATE, Template, UPDATE_TEMPLATE } from "@src/queries/template"
import Loading from "@src/components/loading"
import PostcardPreview from "@src/components/postcard"
import {
  LeftContainer,
  RightContainer,
} from "@src/sections/editCampaign/containers"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import { createUrl } from "@src/utils/createUrl"
import PostcardPreviewContext from "@src/components/postcard/previewContext"
import { string2cmyk, cmyk2hex } from "@src/utils/color"
import { postcards4x6 } from "@src/sections/createCampaign/content/templates"

interface SelectColorProps {
  campaignId?: number
  templateId?: number
}

const SelectColor = ({
  campaignId,
  templateId,
}: SelectColorProps & RouteComponentProps): React.ReactElement => {
  const { addToast } = useToasts()

  // page content
  const colorContent = React.useContext(Localization).selectColor

  // form
  const [form] = Form.useForm()

  // template for preview
  const {
    template: previewTemplate,
    setTemplate: setPreviewTemplate,
  } = React.useContext(PostcardPreviewContext)

  // get template
  const getTemplateResp = useQuery(GET_TEMPLATE, {
    variables: { templateId },
    skip: !templateId,
  })

  // update template
  const [updateTemplateMutation, updateTemplateResp] = useMutation(
    UPDATE_TEMPLATE
  )

  // template data
  const template =
    getTemplateResp &&
    getTemplateResp.data &&
    (getTemplateResp.data.template as Template)

  React.useEffect(() => {
    if (template) {
      setPreviewTemplate && setPreviewTemplate(template)
    }
  }, [getTemplateResp])

  // on form submit
  const onSubmit = async () => {
    try {
      const values = await form.validateFields()
      const selectedPalette = palettes.find(
        p => p.colorCodeId === values.colorCodeId
      )

      // update template
      await updateTemplateMutation({
        variables: {
          templateId,
          body: {
            ...template,
            ...selectedPalette,
          },
        },
      })
    } catch (e) {
      console.error(e)
      addToast(e.message || "Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      })
      throw e
    }
  }

  // show loader
  if (getTemplateResp.loading || !previewTemplate)
    return <Loading withOverlay={true} />

  // selected template
  const selectedTemplate =
    previewTemplate &&
    postcards4x6.find(
      p => p.template.baseTemplateId === previewTemplate.baseTemplateId
    )

  return (
    <div css={[tw`flex bg-white flex-col`]}>
      {/* show loading during update */}
      {updateTemplateResp.loading && <Loading withOverlay={true} />}

      {/* form */}
      <Form
        name="selectColor"
        form={form}
        initialValues={{
          colorCodeId: template.colorCodeId,
        }}
      >
        <CustomContainer customCss={tw`flex flex-col`}>
          {/* campaign navigation */}
          <CampaignNavigation campaignId={campaignId} templateId={templateId} />

          {/* campaign orange info bar */}
          <InfoBarWithContext />

          {/* postcard editing */}
          <div tw="flex mt-4 items-end">
            {/* left container */}
            <LeftContainer>
              {previewTemplate && <PostcardPreview />}
            </LeftContainer>

            {/* right container */}
            <RightContainer
              heading={colorContent.heading}
              description={colorContent.description}
            >
              <Form.Item name="colorCodeId" css={tw`mb-0`}>
                <Radio.Group
                  onChange={async () => {
                    const values = await form.validateFields()
                    const selectedPalette = palettes.find(
                      p => p.colorCodeId === values.colorCodeId
                    )

                    // update template
                    setPreviewTemplate &&
                      setPreviewTemplate({
                        ...template,
                        ...selectedPalette,
                      })
                  }}
                  css={[
                    tw`flex! flex-row! flex-wrap! mt-6!`,
                    css`
                      & .ant-radio-inner::after {
                        background-color: #000;
                      }
                    `,
                  ]}
                >
                  {palettes.map((palette, index) => (
                    <div
                      tw="flex w-1/2 justify-center"
                      key={palette.colorCodeId}
                    >
                      <Radio value={palette.colorCodeId}>
                        <span tw="text-black text-2xs">
                          {colorContent.palette} {index + 1}
                        </span>
                        <div tw="flex mt-1">
                          {[
                            palette.colorCode1,
                            palette.colorCode2,
                            palette.colorCode3,
                            palette.colorCode4,
                          ].map(oneColor => (
                            <div
                              tw="w-6 h-8 border border-gray-400 rounded"
                              css={css`
                                background-color: ${cmyk2hex(
                                  string2cmyk(oneColor)
                                )};
                              `}
                              key={oneColor}
                            />
                          ))}
                        </div>
                      </Radio>
                    </div>
                  ))}
                </Radio.Group>
              </Form.Item>
            </RightContainer>
          </div>
        </CustomContainer>

        {/* action bar */}
        <ActionBar
          backBtnText=""
          saveBtnOnClick={async () => {
            await onSubmit()
          }}
          nextBtnOnClick={async () => {
            await onSubmit()

            campaignId &&
              templateId &&
              selectedTemplate &&
              navigate(
                createUrl(
                  navigationRoutes[
                    selectedTemplate.routes[PageOptions.COLOR]
                      .next as PageOptions
                  ],
                  {
                    campaignId,
                    templateId,
                  }
                )
              )
          }}
          withBoundaries={true}
          rightContainerCss={tw`w-4/12!`}
        />
      </Form>
    </div>
  )
}

export default SelectColor
