import React from "react"
import tw, { css } from "twin.macro"
import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby"
import { useQuery, useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import { Form, Input, Select } from "antd"
import ReactTooltip from "react-tooltip"

import { Localization } from "@src/localization"
import CampaignNavigation from "@src/components/campaign/navigation"
import ActionBar from "@src/components/campaign/actionBar"
import InfoBarWithContext from "@src/components/infoBar/infoBarWithContext"
import CustomContainer from "@src/components/customContainer"
import { GET_TEMPLATE, Template, UPDATE_TEMPLATE } from "@src/queries/template"
import Loading from "@src/components/loading"
import {
  LeftContainer,
  RightContainer,
} from "@src/sections/editCampaign/containers"
import PostcardPreview from "@src/components/postcard"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import { createUrl } from "@src/utils/createUrl"
import PostcardPreviewContext from "@src/components/postcard/previewContext"
import InputLabel from "./InputLabel"
import CustomToolTip from "./CustomToolTip"
import { GET_CAMPAIGN, Campaign, UPDATE_CAMPAIGN } from "@src/queries/campaign"
import {
  mailingFrequencies,
  postcards4x6,
} from "@src/sections/createCampaign/content/templates"

interface SelectDetailsProps {
  campaignId?: number
  templateId?: number
}

const SelectDetails = ({
  campaignId,
  templateId,
}: SelectDetailsProps & RouteComponentProps): React.ReactElement => {
  const { addToast } = useToasts()

  // page content
  const detailsContent = React.useContext(Localization).selectDetails

  // form
  const [form] = Form.useForm()

  // template for preview
  const {
    template: previewTemplate,
    setTemplate: setPreviewTemplate,
  } = React.useContext(PostcardPreviewContext)

  // get template
  const getTemplateResp = useQuery(GET_TEMPLATE, {
    variables: { templateId },
    skip: !templateId,
  })

  // update template
  const [updateTemplateMutation, updateTemplateResp] = useMutation(
    UPDATE_TEMPLATE
  )

  // update campaign
  const [updateCampaignMutation, updateCampaignResp] = useMutation(
    UPDATE_CAMPAIGN
  )

  // get campaign
  const getCampaignResp = useQuery(GET_CAMPAIGN, {
    variables: { campaignId },
    skip: !campaignId,
  })

  // template data
  const template =
    getTemplateResp &&
    getTemplateResp.data &&
    (getTemplateResp.data.template as Template)

  // campaign data
  const campaign =
    getCampaignResp &&
    getCampaignResp.data &&
    (getCampaignResp.data.campaign as Campaign)

  React.useEffect(() => {
    if (template) {
      setPreviewTemplate && setPreviewTemplate(template)
    }
  }, [getTemplateResp])

  // on form submit
  const onSubmit = async () => {
    try {
      const values = await form.validateFields()

      // update template
      await updateTemplateMutation({
        variables: {
          templateId,
          body: {
            ...previewTemplate,
            ...values.template,
          },
        },
      })

      // update campaign
      await updateCampaignMutation({
        variables: {
          campaignId,
          body: {
            ...campaign,
            ...values.campaign,
          },
        },
      })
    } catch (e) {
      console.error(e)
      addToast(
        "errorFields" in e && e.errorFields && e.errorFields.length > 0
          ? "Please fill all the required fields."
          : e.message || "Something went wrong 3",
        { appearance: "error", autoDismiss: true }
      )
      throw e
    }
  }

  // show loader
  if (getTemplateResp.loading || getCampaignResp.loading || !previewTemplate)
    return <Loading withOverlay={true} />

  // selected template
  const selectedTemplate =
    previewTemplate &&
    postcards4x6.find(
      p => p.template.baseTemplateId === previewTemplate.baseTemplateId
    )

  return (
    <div css={[tw`flex bg-white flex-col`]}>
      {/* show loading during update */}
      {(updateTemplateResp.loading || updateCampaignResp.loading) && (
        <Loading withOverlay={true} />
      )}

      <CustomContainer customCss={tw`flex flex-col`}>
        {/* campaign navigation */}
        <CampaignNavigation campaignId={campaignId} templateId={templateId} />

        {/* campaign orange info bar */}
        <InfoBarWithContext />

        {/* tooltip */}
        <ReactTooltip
          id="selectDetailsFieldTip"
          place="top"
          effect="solid"
          borderColor="#000"
        />

        {/* postcard editing */}
        <div tw="flex mt-4 items-end">
          {/* left container */}
          <LeftContainer customCss={tw`w-6/12!`}>
            {previewTemplate && (
              <div
                css={[
                  tw`relative w-full overflow-hidden`,
                  css`
                    height: 400px;
                    background-blend-mode: multiply;
                    background-image: radial-gradient(
                      circle at 50% 57%,
                      white,
                      #8f8f8f 105%
                    );
                  `,
                ]}
              >
                <PostcardPreviewContext.Provider
                  value={{
                    isFront: true,
                    template: previewTemplate,
                    setTemplate: setPreviewTemplate,
                  }}
                >
                  <div
                    css={[
                      tw`absolute`,
                      css`
                        top: -14%;
                        left: -14%;
                        box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
                        transform: scale(0.6);
                      `,
                    ]}
                  >
                    <PostcardPreview />
                  </div>
                </PostcardPreviewContext.Provider>
                <PostcardPreviewContext.Provider
                  value={{
                    isFront: false,
                    template: previewTemplate,
                    setTemplate: setPreviewTemplate,
                  }}
                >
                  <div
                    css={[
                      tw`absolute`,
                      css`
                        bottom: -16%;
                        right: -14%;
                        box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
                        transform: scale(0.6);
                      `,
                    ]}
                  >
                    <PostcardPreview />
                  </div>
                </PostcardPreviewContext.Provider>
              </div>
            )}
          </LeftContainer>

          {/* right container */}
          <RightContainer
            heading={detailsContent.heading}
            description={detailsContent.description}
            customCss={[
              tw`w-5/12! overflow-scroll`,
              css`
                max-height: 410px;
                & .ant-select-selector,
                & .ant-input {
                  border-radius: 50px !important;
                  font-size: 0.75rem;
                }
                & textarea.ant-input {
                  border-radius: 14px !important;
                }
                & .ant-row.ant-form-item {
                  margin-bottom: 0px !important;
                }
              `,
            ]}
          >
            {/* form */}
            <Form
              name="selectDetails"
              form={form}
              initialValues={{
                campaign,
                template,
              }}
              onValuesChange={changedValues => {
                setPreviewTemplate &&
                  setPreviewTemplate({
                    ...(previewTemplate as Template),
                    ...changedValues,
                  })
              }}
            >
              {/* campaign name */}
              <div tw="flex mt-4 py-1 justify-between">
                <InputLabel isRequired customCss={tw`pt-2`}>
                  {detailsContent.campaignName}
                </InputLabel>
                <div tw="flex">
                  <Form.Item
                    name={["campaign", "name"]}
                    rules={[
                      {
                        required: true,
                        message: "Please input campaign name!",
                      },
                    ]}
                    css={tw`flex mx-2 w-64`}
                  >
                    <Input />
                  </Form.Item>
                  <CustomToolTip title={detailsContent.campaignNameInfo} />
                </div>
              </div>

              {/* template name */}
              <div tw="flex py-1 justify-between">
                <InputLabel isRequired customCss={tw`pt-2`}>
                  {detailsContent.templateName}
                </InputLabel>
                <div tw="flex">
                  <Form.Item
                    name={["template", "name"]}
                    css={tw`flex mx-2 w-64`}
                  >
                    <Input />
                  </Form.Item>
                  <CustomToolTip title={detailsContent.templateNameInfo} />
                </div>
              </div>

              {/* mailing frequency */}
              <div tw="flex py-1 justify-between">
                <InputLabel isRequired customCss={tw`pt-2`}>
                  {detailsContent.mailFrequency}
                </InputLabel>
                <div tw="flex flex-col">
                  <div tw="flex">
                    <Form.Item
                      name={["campaign", "repeatEveryDays"]}
                      css={tw`flex mx-2 w-64`}
                      rules={[
                        {
                          required: true,
                          message: "Please select mailing frequency!",
                        },
                      ]}
                    >
                      <Select placeholder="Please select mailing frequency">
                        {mailingFrequencies.map(p => (
                          <Select.Option key={p.value} value={p.value}>
                            {p.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <CustomToolTip title={detailsContent.mailFrequencyInfo} />
                  </div>
                  <div tw="text-black text-2xs flex pl-2">
                    {detailsContent.mailFrequencySubLabel}
                  </div>
                </div>
              </div>

              {/* mailing variables */}
              <div tw="flex py-3 justify-between">
                <InputLabel isRequired customCss={tw`pt-2`}>
                  {detailsContent.templateDescription}
                </InputLabel>
                <div tw="flex">
                  <Form.Item
                    name={["template", "description"]}
                    css={tw`flex mx-2 w-64`}
                    rules={[
                      {
                        required: true,
                        message: "Please input template description!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder={
                        detailsContent.templateDescriptionPlaceholder
                      }
                    />
                  </Form.Item>
                  <CustomToolTip
                    title={detailsContent.templateDescriptionInfo}
                  />
                </div>
              </div>

              {/* button info */}
              <div
                tw="py-2 text-black text-2xs"
                dangerouslySetInnerHTML={{
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  __html: detailsContent.btnInfo,
                }}
              />

              {/* campaign description */}
              <div tw="flex py-3 justify-between">
                <InputLabel customCss={tw`pt-2`}>
                  {detailsContent.campaignDescription}
                </InputLabel>
                <div tw="flex">
                  <Form.Item
                    name={["campaign", "description"]}
                    css={tw`flex mx-2 w-64`}
                  >
                    <Input.TextArea
                      placeholder={
                        detailsContent.campaignDescriptionPlaceholder
                      }
                    />
                  </Form.Item>
                  <CustomToolTip
                    title={detailsContent.campaignDescriptionInfo}
                  />
                </div>
              </div>
            </Form>
          </RightContainer>
        </div>
      </CustomContainer>

      {/* action bar */}
      <ActionBar
        withBoundaries={true}
        leftContainerCss={tw`w-6/12!`}
        rightContainerCss={tw`w-5/12!`}
        backBtnOnClick={() =>
          campaignId &&
          templateId &&
          selectedTemplate &&
          navigate(
            createUrl(
              navigationRoutes[
                selectedTemplate.routes[PageOptions.DETAILS].prev as PageOptions
              ],
              {
                campaignId,
                templateId,
              }
            )
          )
        }
        saveBtnOnClick={async () => {
          await onSubmit()
        }}
        nextBtnOnClick={async () => {
          await onSubmit()

          campaignId &&
            selectedTemplate &&
            navigate(
              createUrl(navigationRoutes[PageOptions.SUBMITCAMPAIGN], {
                campaignId,
              })
            )
        }}
      />
    </div>
  )
}

export default SelectDetails
