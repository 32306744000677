import React from "react"
import tw, { css } from "twin.macro"
import { useToasts } from "react-toast-notifications"
import { useMutation, useQuery } from "@apollo/client"
import MultiSelect from "react-multi-select-component"

import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomModal from "@src/components/ui/modal"
import {
  UPLOAD_IN_GALLERY,
  UploadFileInput,
  UploadType,
  GalleryUploadResponse,
  GET_GALLERY,
} from "@src/queries/gallery"
import { GET_USER, User } from "@src/queries/user"
import Loading from "@src/components/loading"
import useImgBase64 from "@src/hooks/useImgBase64"
import { Localization } from "@src/localization"

const Footer = ({ onSubmit }: { onSubmit: any }) => (
  <div tw="flex py-3 px-16 justify-end">
    <CustomButton
      color={ButtonColors.orange}
      onClick={() => onSubmit()}
      customCss={tw`uppercase`}
    >
      Submit
    </CustomButton>
  </div>
)

interface UploadFileModalProps {
  uploadType: UploadType
  file: File
  onCancel?: () => void
  onSuccess?: (fileUrl: string) => void
}

export interface MultiSelectOption {
  label: string
  value: any
}

const UploadFileModal = ({
  uploadType,
  file,
  onCancel,
  onSuccess,
}: UploadFileModalProps): React.ReactElement => {
  const { addToast } = useToasts()

  const fileUploadContent = React.useContext(Localization).fileUpload

  const imageUrl = useImgBase64(file)

  // get user
  const getUserResp = useQuery(GET_USER)

  // upload image mutation
  const [uploadImageMutation, uploadImageResp] = useMutation(UPLOAD_IN_GALLERY)

  // on success of upload image
  React.useEffect(() => {
    if (
      uploadImageResp &&
      uploadImageResp.data &&
      !uploadImageResp.loading &&
      !uploadImageResp.error &&
      onSuccess
    ) {
      const uploadedFile = uploadImageResp.data.image as GalleryUploadResponse
      onSuccess(uploadedFile.fileUrl)
    }
  }, [uploadImageResp])

  // selected locKeys and acctKeys
  const [selectedLocKeys, setSelectedLocKeys] = React.useState<
    MultiSelectOption[]
  >([])
  const [selectedAcctKeys, setSelectedAcctKeys] = React.useState<
    MultiSelectOption[]
  >([])

  // user submits the image for uploading
  const onSubmit = async () => {
    try {
      const uploadImageBody: UploadFileInput = {
        LocKeys: selectedLocKeys.map(l => l.value),
        AcctKeys: selectedAcctKeys.map(p => p.value),
        File: file,
        UploadType: uploadType,
      }

      await uploadImageMutation({
        variables: {
          body: uploadImageBody,
        },
        refetchQueries: [
          {
            query: GET_GALLERY,
            variables: {
              uploadType,
            },
          },
        ],
      })
    } catch (e) {
      console.error(e)
      addToast(e.message || "Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      })
      throw e
    }
  }

  // show loading
  if (!getUserResp || !getUserResp.data) {
    return <Loading withOverlay />
  }

  const user =
    getUserResp && getUserResp.data && (getUserResp.data.user as User)

  const locOptions: MultiSelectOption[] = user.locations.map(l => {
    return {
      label: l.orgName,
      value: l.locKey,
    }
  })

  const acctOptions: MultiSelectOption[] = user.providers.map(p => {
    return {
      label: `${p.title} ${p.firstName} ${p.lastName} ${p.degree}`,
      value: p.acctKey,
    }
  })

  return (
    <CustomModal
      title={null}
      isVisible={true}
      onCancel={() => {
        console.log("cancel")
        onCancel && onCancel()
      }}
      footer={<Footer onSubmit={() => onSubmit()} />}
      width={700}
    >
      {uploadImageResp.loading && <Loading withOverlay />}
      <div tw="flex flex-col items-center">
        <div
          css={[
            tw`flex border border-dashed border-gray-400 bg-contain bg-no-repeat bg-center w-8/12 h-64 mt-8 mb-4 bg-gray-400`,
            css`
              ${imageUrl ? `background-image: url(${imageUrl})` : ""}
            `,
          ]}
        />

        <div css={tw`text-black mb-8 font-bold`}>
          {fileUploadContent.tagDescription}
        </div>

        <div tw="flex mb-2">
          <div tw="flex px-3 items-center">
            <span css={tw`text-xs text-black mr-2 font-bold`}>
              {fileUploadContent.location}
            </span>
            <MultiSelect
              options={locOptions}
              value={selectedLocKeys}
              onChange={setSelectedLocKeys}
              labelledBy={"Locations"}
              css={[
                tw`w-48 text-xs`,
                css`
                  & .dropdown-container {
                    border-radius: 50px;
                  }
                  & .dropdown-content {
                    width: 300px !important;
                  }
                `,
              ]}
            />
          </div>

          <div tw="flex px-3 items-center">
            <span css={tw`text-xs text-black mr-2 font-bold`}>
              {fileUploadContent.provider}
            </span>
            <MultiSelect
              options={acctOptions}
              value={selectedAcctKeys}
              onChange={setSelectedAcctKeys}
              labelledBy={"Providers"}
              css={[
                tw`w-48 text-xs`,
                css`
                  & .dropdown-container {
                    border-radius: 50px;
                  }
                  & .dropdown-content {
                    width: 300px !important;
                  }
                `,
              ]}
            />
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

export default UploadFileModal
