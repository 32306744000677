import React from "react"
import tw, { css } from "twin.macro"

import PostcardPreviewContext from "../previewContext"
import { cmyk2hex, string2cmyk } from "@src/utils/color"
import EditableField from "../EditableField"
import { TextFields } from "@src/queries/template"
import CopyContext from "@src/context/copyContext"
import Logo from "../Logo"

const RecallTemplate3 = ({
  clearField,
  editable,
  updateFormFields,
}: {
  editable: boolean
  clearField: (fieldName: string) => void
  updateFormFields: (updateFields: any) => void
}): React.ReactElement | null => {
  const { template, isFront } = React.useContext(PostcardPreviewContext)

  // copy context
  const { field, setField } = React.useContext(CopyContext)

  if (!template) {
    return null
  }

  return isFront ? (
    <div
      css={[
        tw`relative bg-center w-full h-full bg-no-repeat overflow-hidden`,
        template.frontPhotoScaleToFit ? tw`bg-contain` : tw`bg-cover`,
        css`
          ${template.frontPhotoPublicUrl
            ? `background-image: url(${template.frontPhotoPublicUrl})`
            : ""};
        `,
      ]}
    >
      <div
        css={[
          tw`absolute left-0`,
          css`
            top: -20px;
            height: calc(100% + 40px);
            width: 42.3%;
            border-bottom-right-radius: 40% 50%;
            border-top-right-radius: 40% 50%;
            background-color: ${cmyk2hex(string2cmyk(template.colorCode2))};
          `,
        ]}
      />
      <div
        css={[
          tw`flex flex-col px-2 pt-16 pb-4 justify-between w-full h-full`,
          css`
            width: 42%;
          `,
        ]}
      >
        {/* message2 field */}
        <EditableField
          field={field}
          textField={TextFields.message2}
          clearField={clearField}
          template={template}
          editable={editable}
          setField={setField}
          updateFormFields={updateFormFields}
          customCss={tw`truncate py-2`}
        />

        {/* message3 field */}
        <div tw="w-9/12!">
          <EditableField
            field={field}
            textField={TextFields.message3}
            clearField={clearField}
            template={template}
            editable={editable}
            setField={setField}
            updateFormFields={updateFormFields}
            customCss={[
              tw`py-2 text-left!`,
              css`
                line-height: 1.3em !important;
              `,
            ]}
          />
        </div>

        <div tw="flex w-5/12 h-16 self-center z-10">
          <Logo
            img={template.frontLogoPublicUrl}
            scaleToFit={template.frontLogoScaleToFit}
          />
        </div>
      </div>
    </div>
  ) : (
    <div tw="flex flex-col w-full h-full">
      <div
        css={[
          tw`flex p-2`,
          css`
            height: 38%;
            background-color: ${cmyk2hex(string2cmyk(template.colorCode3))};
          `,
        ]}
      >
        <div
          css={[
            tw`flex flex-col`,
            css`
              width: 32%;
              color: ${cmyk2hex(string2cmyk(template.colorCode1))};
            `,
          ]}
        >
          <div css={tw`flex flex-grow w-1/2`}>
            <Logo
              img={template.backLogoPublicUrl}
              scaleToFit={template.backLogoScaleToFit}
            />
          </div>
          <div css={tw`mt-2 pb-1`}>
            {/* message10 field */}
            <EditableField
              field={field}
              textField={TextFields.message10}
              clearField={clearField}
              template={template}
              editable={editable}
              setField={setField}
              updateFormFields={updateFormFields}
              customCss={css`
                line-height: 1.2em !important;
                text-align: left !important;
              `}
            />
          </div>
        </div>
        <div
          css={[
            tw`flex px-2 items-center`,
            css`
              width: 68%;
            `,
          ]}
        >
          <div tw="flex flex-col mr-2 h-full w-8/12 justify-between items-end">
            <div tw="w-9/12!">
              {/* message9 field */}
              <EditableField
                field={field}
                textField={TextFields.message9}
                clearField={clearField}
                template={template}
                editable={editable}
                setField={setField}
                updateFormFields={updateFormFields}
                customCss={[
                  tw`text-right!`,
                  css`
                    line-height: 1.2em !important;
                  `,
                ]}
              />
            </div>

            {/* message8 field */}
            <EditableField
              field={field}
              textField={TextFields.message8}
              clearField={clearField}
              template={template}
              editable={editable}
              setField={setField}
              updateFormFields={updateFormFields}
              customCss={tw`truncate py-2 text-right!`}
            />
          </div>
          <div
            tw="flex h-full w-4/12"
            css={[
              tw`flex bg-center bg-no-repeat`,
              template.backPhotoScaleToFit ? tw`bg-contain` : tw`bg-cover`,
              css`
                ${template.backPhotoPublicUrl
                  ? `background-image: url(${template.backPhotoPublicUrl})`
                  : ""};
              `,
            ]}
          />
        </div>
      </div>
      <div
        css={[
          tw`flex`,
          css`
            height: 62%;
          `,
        ]}
      >
        <div
          css={[
            tw`flex flex-col px-2 py-4`,
            css`
              width: 32%;
              background-color: ${cmyk2hex(string2cmyk(template.colorCode2))};
            `,
          ]}
        >
          {/* message7 field */}
          <EditableField
            field={field}
            textField={TextFields.message7}
            clearField={clearField}
            template={template}
            editable={editable}
            setField={setField}
            updateFormFields={updateFormFields}
            customCss={[
              tw`py-2 text-left!`,
              css`
                line-height: 1.8em !important;
              `,
            ]}
          />
        </div>
        <div
          css={[
            tw`flex px-2 items-center`,
            css`
              width: 68%;
              color: ${cmyk2hex(string2cmyk(template.colorCode4))};
              background-color: ${cmyk2hex(string2cmyk(template.colorCode1))};
            `,
          ]}
        >
          <div tw="flex w-1/2">
            <div
              css={[
                css`
                  text-align: left;
                  font-size: 7pt;
                  line-height: 1.4em;
                  font-weight: bold;
                `,
              ]}
            >
              {"<<PATIENT_FIRST_NAME>> <<PATIENT_LAST_NAME>>"}
              <br />
              {"<<PATIENT_ADDRESS_1>>"}
              <br />
              {"<<PATIENT_ADDRESS_2>>"}
            </div>
          </div>
          <div tw="flex w-1/2 justify-end self-start">
            <div
              css={[
                tw`flex border border-black mt-4 mr-4 p-2`,
                css`
                  text-align: center;
                  font-size: 7pt;
                  line-height: 1em;
                `,
              ]}
            >
              PRESORTED
              <br />
              FIRST CLASS MAIN
              <br /> U.S. POSTAGE
              <br />
              PAID
              <br />
              PATIENTCARE
              <br />
              89510
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecallTemplate3
