import React from "react"
import tw, { css } from "twin.macro"
import { useQuery } from "@apollo/client"
import MultiSelect from "react-multi-select-component"
import { Radio, Form } from "antd"
import { RightOutlined } from "@ant-design/icons"

import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomModal from "@src/components/ui/modal"
import { UploadType, GET_GALLERY, Gallery } from "@src/queries/gallery"
import { GET_USER, User } from "@src/queries/user"
import Loading from "@src/components/loading"
import { MultiSelectOption } from "@src/components/fileUpload/tagUploadingFile"
import { Localization } from "@src/localization"

const Footer = ({
  uploadType,
  onSubmit,
  onCancel,
  isSubmitActive,
  isPublicLib,
  setIsPublicLib,
}: {
  uploadType: UploadType
  onSubmit: any
  onCancel: any
  isSubmitActive: boolean
  isPublicLib: boolean
  setIsPublicLib: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  // page content
  const fileContent = React.useContext(Localization).fileSelection

  return (
    <CustomModal.Footer heading="">
      <div tw="flex justify-end">
        {uploadType === UploadType.Background && (
          <CustomButton
            color={ButtonColors.white}
            onClick={() => {
              setIsPublicLib(!isPublicLib)
            }}
            customCss={tw`uppercase`}
          >
            {isPublicLib ? fileContent.corporateLib : fileContent.publicLib}{" "}
            <RightOutlined tw="ml-2" />
          </CustomButton>
        )}

        <CustomButton
          color={ButtonColors.white}
          onClick={() => onCancel()}
          customCss={tw`uppercase`}
        >
          Cancel
        </CustomButton>

        <CustomButton
          color={ButtonColors.orange}
          onClick={() => onSubmit()}
          disabled={!isSubmitActive}
          customCss={tw`uppercase`}
        >
          Submit
        </CustomButton>
      </div>
    </CustomModal.Footer>
  )
}
const Title = ({
  isPublicLib,
  locOptions,
  acctOptions,
  selectedLocKeys,
  setSelectedLocKeys,
  selectedAcctKeys,
  setSelectedAcctKeys,
}: {
  isPublicLib: boolean
  locOptions: MultiSelectOption[]
  acctOptions: MultiSelectOption[]
  selectedLocKeys: MultiSelectOption[]
  setSelectedLocKeys: React.Dispatch<React.SetStateAction<MultiSelectOption[]>>
  selectedAcctKeys: MultiSelectOption[]
  setSelectedAcctKeys: React.Dispatch<React.SetStateAction<MultiSelectOption[]>>
}) => {
  // page content
  const fileContent = React.useContext(Localization).fileSelection

  return (
    <CustomModal.Title
      heading={
        isPublicLib ? fileContent.publicAssets : fileContent.corporateAssets
      }
    >
      {!isPublicLib && (
        <div tw="flex">
          <div tw="flex px-3 items-center">
            <span css={tw`text-xs text-black mr-2 font-bold`}>
              {fileContent.sortByLocation}
            </span>
            <MultiSelect
              options={locOptions}
              value={selectedLocKeys}
              onChange={setSelectedLocKeys}
              labelledBy={"Locations"}
              css={[
                tw`w-40 text-xs`,
                css`
                  & .dropdown-container {
                    border-radius: 50px;
                  }
                  & .dropdown-content {
                    width: 300px !important;
                    z-index: 100;
                  }
                `,
              ]}
            />
          </div>

          <div tw="flex px-3 items-center">
            <span css={tw`text-xs text-black mr-2 font-bold`}>
              {fileContent.sortByProvider}
            </span>
            <MultiSelect
              options={acctOptions}
              value={selectedAcctKeys}
              onChange={setSelectedAcctKeys}
              labelledBy={"Providers"}
              css={[
                tw`w-40 text-xs`,
                css`
                  & .dropdown-container {
                    border-radius: 50px;
                  }
                  & .dropdown-content {
                    width: 300px !important;
                    z-index: 100;
                  }
                `,
              ]}
            />
          </div>
        </div>
      )}
    </CustomModal.Title>
  )
}

interface GalleryModalProps {
  uploadType: UploadType
  onCancel: any
  onSuccess: (fileUrl: string) => void
}

const GalleryModal = ({
  uploadType,
  onCancel,
  onSuccess,
}: GalleryModalProps): React.ReactElement => {
  // form
  const [form] = Form.useForm()

  // page content
  const fileContent = React.useContext(Localization).fileSelection

  // get user
  const getUserResp = useQuery(GET_USER)

  // get gallery
  const getGalleryResp = useQuery(GET_GALLERY, {
    variables: {
      uploadType,
    },
  })

  // selected locKeys and acctKeys
  const [selectedLocKeys, setSelectedLocKeys] = React.useState<
    MultiSelectOption[]
  >([])
  const [selectedAcctKeys, setSelectedAcctKeys] = React.useState<
    MultiSelectOption[]
  >([])

  // is public library
  const [isPublicLib, setIsPublicLib] = React.useState<boolean>(false)

  // selected image
  const [selectedImage, setSelectedImage] = React.useState<string | null>(null)

  // reset filter for public library
  React.useEffect(() => {
    if (isPublicLib) {
      setSelectedAcctKeys([])
      setSelectedLocKeys([])
    }
  }, [isPublicLib])

  // show loading
  if (getUserResp.loading || getGalleryResp.loading) {
    return <Loading withOverlay />
  }

  // api data
  const user =
    getUserResp && getUserResp.data && (getUserResp.data.user as User)
  const images =
    getGalleryResp &&
    getGalleryResp.data &&
    (getGalleryResp.data.images as Gallery[])

  const locOptions: MultiSelectOption[] = user.locations.map(l => {
    return {
      label: l.orgName,
      value: l.locKey,
    }
  })

  const acctOptions: MultiSelectOption[] = user.providers.map(p => {
    return {
      label: `${p.title} ${p.firstName} ${p.lastName} ${p.degree}`,
      value: p.acctKey,
    }
  })

  // filter images based on section
  let sortedImages = images.filter(i =>
    isPublicLib ? !i.galleryCoverKey : i.galleryCoverKey
  )

  // apply sorting on images
  if (sortedImages && sortedImages.length > 0) {
    if (selectedLocKeys && selectedLocKeys.length > 0) {
      sortedImages = sortedImages.sort(a => {
        const found =
          a.listLocKey &&
          a.listLocKey.some(r => selectedLocKeys.map(l => l.value).includes(r))

        if (found) return -1
        return 1
      })
    }

    // sort by providers
    if (selectedAcctKeys && selectedAcctKeys.length > 0) {
      sortedImages = sortedImages.sort(a => {
        const found =
          a.listAcctKey &&
          a.listAcctKey.some(r =>
            selectedAcctKeys.map(l => l.value).includes(r)
          )

        if (found) return -1
        return 1
      })
    }
  }

  const onSubmit = () => {
    selectedImage && onSuccess && onSuccess(selectedImage)
  }

  return (
    <CustomModal
      title={
        <Title
          isPublicLib={isPublicLib}
          locOptions={locOptions}
          acctOptions={acctOptions}
          selectedLocKeys={selectedLocKeys}
          setSelectedLocKeys={setSelectedLocKeys}
          selectedAcctKeys={selectedAcctKeys}
          setSelectedAcctKeys={setSelectedAcctKeys}
        />
      }
      isVisible={true}
      onCancel={() => onCancel()}
      footer={
        <Footer
          uploadType={uploadType}
          isSubmitActive={!!selectedImage}
          onSubmit={() => onSubmit()}
          onCancel={onCancel}
          isPublicLib={isPublicLib}
          setIsPublicLib={setIsPublicLib}
        />
      }
      width={960}
    >
      <Form
        name="gallery"
        form={form}
        onValuesChange={changedValues => {
          if ("selected-image" in changedValues) {
            setSelectedImage(changedValues["selected-image"])
          }
        }}
      >
        <div tw="flex px-16 py-8">
          <div tw="flex flex-col w-5/12">
            <div
              css={[
                tw`flex border border-dashed items-center justify-center bg-contain bg-no-repeat bg-center border-gray-500 text-xs text-center w-full h-64`,
                css`
                  line-height: 1em;
                  ${selectedImage
                    ? [
                        css`
                          background-image: url(${selectedImage});
                        `,
                        uploadType === UploadType.Logo && tw`bg-gray-400`,
                      ]
                    : ""}
                `,
              ]}
            >
              {!selectedImage && <>{fileContent.selectionAppearHere}</>}
            </div>

            <p tw="mt-6 text-xs text-black">
              {isPublicLib
                ? fileContent.publicLibBgDesc
                : uploadType === UploadType.Background
                ? fileContent.corporateLibBgDesc
                : fileContent.corporateLibLogoDesc}
            </p>
          </div>

          <div
            css={[
              tw`flex flex-wrap overflow-auto pl-4 w-7/12`,
              css`
                max-height: 60vh;
                & .ant-radio-group {
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  width: 100%;
                }
                & .ant-radio-wrapper {
                  width: 100%;
                  height: 100%;
                  margin: 0 0 1rem 1rem;
                }
                & .ant-radio {
                  position: absolute;
                  right: 4px;
                  top: 4px;
                  z-index: 20;
                }
                & .ant-radio-inner::after {
                  background-color: #000;
                }
                & .ant-row.ant-form-item {
                  width: 100%;
                }
              `,
            ]}
          >
            <Form.Item name="selected-image">
              <Radio.Group>
                {sortedImages &&
                  sortedImages.map(oneImage => (
                    <div key={oneImage.id} tw="flex w-1/3 h-32 mb-6">
                      <Radio value={oneImage.publicFileUrl}>
                        <div
                          css={[
                            tw`bg-contain bg-no-repeat bg-center w-full h-full border border-gray-400 absolute top-0 z-10 `,
                            uploadType === UploadType.Logo && tw`bg-gray-400`,
                            css`
                              background-image: url(${oneImage.publicFileUrl});
                            `,
                          ]}
                        ></div>
                      </Radio>
                    </div>
                  ))}
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
      </Form>
    </CustomModal>
  )
}

export default GalleryModal
