import React from "react"
import { Router } from "@reach/router"

import SelectLogo from "./selectLogo/"
import SelectColor from "./selectColor/"
import SelectPhoto from "./selectPhoto/"
import SelectCopy from "./selectCopy/"
import SelectDetails from "./selectDetails/"
import {
  PageOptions,
  navigationRoutes,
} from "@src/localization/en/createCampaign"
import SelectTemplate from "@src/sections/createCampaign/selectTemplate"
import PostcardPreviewContext from "@src/components/postcard/previewContext"
import { Template } from "@src/queries/template"
import CopyContext from "@src/context/copyContext"

const EditCampaign = (): React.ReactElement => {
  const [isFront, setIsFront] = React.useState(true)
  const [template, setTemplate] = React.useState<Template | null>(null)
  const [field, setField] = React.useState<string | null>(null)

  return (
    <PostcardPreviewContext.Provider
      value={{
        isFront,
        setIsFront,
        template,
        setTemplate,
      }}
    >
      <CopyContext.Provider
        value={{
          field,
          setField,
        }}
      >
        <Router>
          <SelectTemplate path={navigationRoutes[PageOptions.ADDTEMPLATE]} />
          <SelectTemplate path={navigationRoutes[PageOptions.EDITTEMPLATE]} />
          <SelectColor path={navigationRoutes[PageOptions.COLOR]} />
          <SelectLogo path={navigationRoutes[PageOptions.LOGO]} />
          <SelectPhoto path={navigationRoutes[PageOptions.PHOTO]} />
          <SelectCopy path={navigationRoutes[PageOptions.COPY]} />
          <SelectDetails path={navigationRoutes[PageOptions.DETAILS]} />
        </Router>
      </CopyContext.Provider>
    </PostcardPreviewContext.Provider>
  )
}

export default EditCampaign
