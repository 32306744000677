import React from "react"
import tw, { css } from "twin.macro"

import PostcardPreviewContext from "../previewContext"
import { cmyk2hex, string2cmyk } from "@src/utils/color"
import EditableField from "../EditableField"
import { TextFields } from "@src/queries/template"
import CopyContext from "@src/context/copyContext"
import Logo from "../Logo"

const RecallTemplate2 = ({
  clearField,
  editable,
  updateFormFields,
}: {
  editable: boolean
  clearField: (fieldName: string) => void
  updateFormFields: (updateFields: any) => void
}): React.ReactElement | null => {
  const { template, isFront } = React.useContext(PostcardPreviewContext)

  // copy context
  const { field, setField } = React.useContext(CopyContext)

  if (!template) {
    return null
  }

  return isFront ? (
    <div
      css={[
        tw`relative bg-center w-full h-full bg-no-repeat`,
        template.frontPhotoScaleToFit ? tw`bg-contain` : tw`bg-cover`,
        css`
          ${template.frontPhotoPublicUrl
            ? `background-image: url(${template.frontPhotoPublicUrl})`
            : ""};
        `,
      ]}
    >
      <div
        css={[
          tw`absolute w-full py-2`,
          css`
            background-color: ${cmyk2hex(string2cmyk(template.colorCode3))};
            bottom: 1rem;
          `,
        ]}
      >
        {/* message1 field */}
        <EditableField
          field={field}
          textField={TextFields.message1}
          clearField={clearField}
          template={template}
          editable={editable}
          setField={setField}
          updateFormFields={updateFormFields}
          customCss={[
            tw`w-full truncate`,
            css`
              line-height: 1.2em !important;
              max-height: 1.3em;
            `,
          ]}
        />
      </div>

      <div
        css={[
          tw`flex flex-col p-2 justify-between w-full h-full`,
          css`
            width: 39.1%;
            background-color: ${cmyk2hex(string2cmyk(template.colorCode2))};
          `,
        ]}
      >
        <div tw="flex w-2/3 h-24 self-center">
          <Logo
            img={template.frontLogoPublicUrl}
            scaleToFit={template.frontLogoScaleToFit}
          />
        </div>

        <div tw="flex self-center w-full">
          {/* message2 field */}
          <EditableField
            field={field}
            textField={TextFields.message2}
            clearField={clearField}
            template={template}
            editable={editable}
            setField={setField}
            updateFormFields={updateFormFields}
            customCss={tw`truncate py-2`}
          />
        </div>

        <div tw="h-24" />
      </div>
    </div>
  ) : (
    <div tw="flex flex-col w-full h-full">
      <div
        css={[
          tw`flex p-2`,
          css`
            height: 38%;
            background-color: ${cmyk2hex(string2cmyk(template.colorCode3))};
          `,
        ]}
      >
        <div
          css={[
            tw`flex flex-col`,
            css`
              width: 32%;
              color: ${cmyk2hex(string2cmyk(template.colorCode1))};
            `,
          ]}
        >
          <div css={tw`flex flex-grow w-1/2`}>
            <Logo
              img={template.backLogoPublicUrl}
              scaleToFit={template.backLogoScaleToFit}
            />
          </div>
          <div css={tw`mt-2 pb-1`}>
            {/* message10 field */}
            <EditableField
              field={field}
              textField={TextFields.message10}
              clearField={clearField}
              template={template}
              editable={editable}
              setField={setField}
              updateFormFields={updateFormFields}
              customCss={css`
                line-height: 1.2em !important;
                text-align: left !important;
              `}
            />
          </div>
        </div>
        <div
          css={[
            tw`flex px-2 items-center`,
            css`
              width: 68%;
            `,
          ]}
        >
          {/* message9 field */}
          <EditableField
            field={field}
            textField={TextFields.message9}
            clearField={clearField}
            template={template}
            editable={editable}
            setField={setField}
            updateFormFields={updateFormFields}
            customCss={css`
              line-height: 1.7em !important;
              text-align: left !important;
            `}
          />
        </div>
      </div>
      <div css={[tw`flex flex-grow`]}>
        <div
          css={[
            tw`flex flex-col bg-center bg-no-repeat`,
            css`
              width: 32%;
            `,
            template.backPhotoScaleToFit ? tw`bg-contain` : tw`bg-cover`,
            css`
              ${template.backPhotoPublicUrl
                ? `background-image: url(${template.backPhotoPublicUrl})`
                : ""};
            `,
          ]}
        ></div>
        <div
          css={[
            tw`flex px-2 items-center`,
            css`
              width: 68%;
              color: ${cmyk2hex(string2cmyk(template.colorCode4))};
              background-color: ${cmyk2hex(string2cmyk(template.colorCode1))};
            `,
          ]}
        >
          <div tw="flex w-1/2">
            <div
              css={[
                css`
                  text-align: left;
                  font-size: 7pt;
                  line-height: 1.4em;
                  font-weight: bold;
                `,
              ]}
            >
              {"<<PATIENT_FIRST_NAME>> <<PATIENT_LAST_NAME>>"}
              <br />
              {"<<PATIENT_ADDRESS_1>>"}
              <br />
              {"<<PATIENT_ADDRESS_2>>"}
            </div>
          </div>
          <div tw="flex w-1/2 justify-end self-start">
            <div
              css={[
                tw`flex border border-black mt-4 mr-4 p-2`,
                css`
                  text-align: center;
                  font-size: 7pt;
                  line-height: 1em;
                `,
              ]}
            >
              PRESORTED
              <br />
              FIRST CLASS MAIN
              <br /> U.S. POSTAGE
              <br />
              PAID
              <br />
              PATIENTCARE
              <br />
              89510
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecallTemplate2
