import React from "react"

interface PostcardPreviewContextProps {
  field: string | null
  setField?: React.Dispatch<React.SetStateAction<string | null>>
}

const CopyContext = React.createContext<PostcardPreviewContextProps>({
  field: null,
})

export default CopyContext
