import React from "react"
import tw from "twin.macro"

export const LeftContainer = ({
  children,
  customCss,
}: {
  children: any
  customCss?: any
}): React.ReactElement => (
  <div css={[tw`w-7/12 pr-8 flex flex-col items-end mb-2`, customCss]}>
    {children}
  </div>
)

export const RightContainer = ({
  children,
  heading,
  description,
  customCss,
}: {
  children: any
  heading: string
  description: string
  customCss?: any
}): React.ReactElement => (
  <div
    css={[
      tw`w-4/12 border border-gray-400 border-b-0 rounded-t-lg p-4`,
      customCss,
    ]}
  >
    <div tw="text-black text-base pb-1 font-bold">{heading}</div>
    <div tw="text-black text-xs">{description}</div>
    {children}
  </div>
)
