import React from "react"
import tw, { css } from "twin.macro"
import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby"
import { useQuery, useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import { Form, Checkbox, Select, Button } from "antd"
import {
  InfoCircleOutlined,
  CaretDownOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons"

import { Localization } from "@src/localization"
import CampaignNavigation from "@src/components/campaign/navigation"
import ActionBar from "@src/components/campaign/actionBar"
import InfoBarWithContext from "@src/components/infoBar/infoBarWithContext"
import CustomContainer from "@src/components/customContainer"
import {
  GET_TEMPLATE,
  Template,
  UPDATE_TEMPLATE,
  CreateTemplateInput,
} from "@src/queries/template"
import Loading from "@src/components/loading"
import {
  LeftContainer,
  RightContainer,
} from "@src/sections/editCampaign/containers"
import PostcardPreview from "@src/components/postcard"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import { createUrl } from "@src/utils/createUrl"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import Bold from "@src/components/ui/bold"
import PostcardPreviewContext from "@src/components/postcard/previewContext"
import GalleryModal from "@src/components/gallery"
import { UploadType } from "@src/queries/gallery"
import FileUpload, { FileFormat } from "@src/components/fileUpload"
import {
  BaseTemplates,
  postcards4x6,
} from "@src/sections/createCampaign/content/templates"
import {
  GET_LOCATION_GALLERY_MAPS,
  LocationGalleryMap,
} from "@src/queries/locationGalleryMap"
import { GET_USER, User } from "@src/queries/user"
import {
  GET_LOCATION_GALLERY_MAPPINGS_BY_LOCATION_GALLERY_MAP_ID,
  UPDATE_LOCATION_GALLERY_MAPPING_MASS,
  GET_LOCATION_GALLERY_MAPPINGS,
  LocationGalleryMapping,
} from "@src/queries/locationGalleryMapping"
import {
  setLocMapIdToSession,
  getLocMapIdFromSession,
} from "@src/utils/storage"

const { Option } = Select

interface SelectPhotoProps {
  campaignId?: number
  templateId?: number
}

const SelectPhoto = ({
  campaignId,
  templateId,
}: SelectPhotoProps & RouteComponentProps): React.ReactElement => {
  const { addToast } = useToasts()

  // page content
  const photoContent = React.useContext(Localization).selectPhoto
  const fileContent = React.useContext(Localization).fileUpload

  const [locationGalleryMapId, setLocationGalleryMapId] = React.useState<
    number
  >(-1)
  const [locKey, setLocKey] = React.useState<string>()
  const [photoUrl, setPhotoUrl] = React.useState<string>()
  const [isChooseFileFromLibrary, setIsChooseFileFromLibrary] = React.useState<
    boolean
  >(false)

  // form
  const [form] = Form.useForm()

  // template for preview
  const {
    isFront,
    template: previewTemplate,
    setTemplate: setPreviewTemplate,
  } = React.useContext(PostcardPreviewContext)

  // get template
  const getTemplateResp = useQuery(GET_TEMPLATE, {
    variables: { templateId },
    skip: !getLocMapIdFromSession(),
  })

  // get user
  const getUserResp = useQuery(GET_USER)

  const user: User =
    getUserResp && getUserResp.data && (getUserResp.data.user as User)

  // get locationGalleryMaps
  const getLocationGalleryMapsResp = useQuery(GET_LOCATION_GALLERY_MAPS)

  const locationGalleryMaps: LocationGalleryMap[] =
    getLocationGalleryMapsResp &&
    getLocationGalleryMapsResp.data &&
    getLocationGalleryMapsResp.data.locationGalleryMaps

  // get locationGalleryMappings
  const getLocationGalleryMappingsResp = useQuery(GET_LOCATION_GALLERY_MAPPINGS)

  const locationGalleryMappings: LocationGalleryMapping[] =
    getLocationGalleryMappingsResp &&
    getLocationGalleryMappingsResp.data &&
    getLocationGalleryMappingsResp.data.locationGalleryMappings

  // get locationGalleryMappingsByLocationGalleryMapId
  const { data, refetch } = useQuery(
    GET_LOCATION_GALLERY_MAPPINGS_BY_LOCATION_GALLERY_MAP_ID,
    {
      variables: { locationGalleryMapId },
      skip: locationGalleryMapId === -1,
    }
  )

  // update template
  const [updateTemplateMutation, updateTemplateResp] = useMutation(
    UPDATE_TEMPLATE
  )

  // update locationGalleryMappingMass
  const [updateLocationGalleryMappingMass] = useMutation(
    UPDATE_LOCATION_GALLERY_MAPPING_MASS
  )

  const draggerRef = React.useRef<HTMLDivElement | null>(null)

  // is gallery open
  const [isGalleryOpen, setIsGalleryOpen] = React.useState<boolean>(false)

  // template data
  const template: Template =
    getTemplateResp &&
    getTemplateResp.data &&
    (getTemplateResp.data.template as Template)

  const setFiles = (photoUrl: string, logoUrl?: string) => {
    const toUpdate: Partial<CreateTemplateInput> = {}

    if (isFront) {
      if (logoUrl) {
        toUpdate.frontLogoPublicUrl = logoUrl
      }
      toUpdate.frontPhotoPublicUrl = photoUrl
    } else {
      if (logoUrl) {
        toUpdate.backLogoPublicUrl = logoUrl
      }
      toUpdate.backPhotoPublicUrl = photoUrl
    }

    setPreviewTemplate &&
      setPreviewTemplate({
        ...(previewTemplate as Template),
        ...toUpdate,
      })
  }

  const getLocationGalleryMaps = () => {
    if (locationGalleryMaps && locationGalleryMappings) {
      const locationGalleryMapsFiltered = locationGalleryMaps.filter(item =>
        locationGalleryMappings.some(
          it =>
            !it.locKey &&
            item.id === it.locationGalleryMapId &&
            it.frontLogoPublicUrl &&
            it.backLogoPublicUrl &&
            it.frontPhotoPublicUrl &&
            it.backPhotoPublicUrl &&
            it.webLink
        )
      )

      const newLocationGalleryMaps = locationGalleryMapsFiltered.map(i =>
        Object.assign({}, i)
      )

      newLocationGalleryMaps.unshift({
        id: -1,
        name: "None",
        coverKey: "none",
      })

      return newLocationGalleryMaps
    }

    return []
  }

  React.useEffect(()=>{
    if(getLocationGalleryMapsResp){
      getLocationGalleryMapsResp.refetch();
    }
    
    if(getLocationGalleryMappingsResp){
      getLocationGalleryMappingsResp.refetch();
    }
  },[])

  React.useEffect(() => {
    if (template) {
      if (template.locationGalleryMapId) {
        setLocationGalleryMapId(template.locationGalleryMapId)
      }

      setPreviewTemplate && setPreviewTemplate(template)
    }
  }, [template])

  React.useEffect(() => {
    if (user && user.locations) {
      setLocKey(user.locations[0].locKey)
    }
  }, [user])

  React.useEffect(() => {
    if (locationGalleryMaps && locationGalleryMaps.length > 0) {
      setLocMapIdToSession(locationGalleryMapId)
    }
  }, [locationGalleryMaps, locationGalleryMapId])

  React.useEffect(() => {
    if (data && data.locationGalleryMappingsByLocationGalleryMapId) {
      const locationGalleryMappingDefault = data.locationGalleryMappingsByLocationGalleryMapId.find(
        (item: any) => !item.locKey
      )

      const locationGalleryMapping = data.locationGalleryMappingsByLocationGalleryMapId.find(
        (item: any) => item.locKey === locKey
      )

      if (locationGalleryMapping || locationGalleryMappingDefault) {
        const frontLogo =
          locationGalleryMapping?.frontLogoPublicUrl ||
          locationGalleryMappingDefault.frontLogoPublicUrl
        const backLogo =
          locationGalleryMapping?.backLogoPublicUrl ||
          locationGalleryMappingDefault.backLogoPublicUrl
        const logoUrl = isFront ? frontLogo : backLogo

        const frontPhoto =
          locationGalleryMapping?.frontPhotoPublicUrl ||
          locationGalleryMappingDefault.frontPhotoPublicUrl
        const backPhoto =
          locationGalleryMapping?.backPhotoPublicUrl ||
          locationGalleryMappingDefault.backPhotoPublicUrl
        const photoUrl = isFront ? frontPhoto : backPhoto

        setPhotoUrl(photoUrl)
        setFiles(photoUrl, logoUrl)
        setIsChooseFileFromLibrary(false)
      }
    }
  }, [data, locKey, isFront])

  const chooseFileFromLibrary = (fileUrl: string) => {
    setPhotoUrl(fileUrl)
    setFiles(fileUrl)
    setIsChooseFileFromLibrary(true)
  }

  // on form submit
  const onSubmit = async () => {
    try {
      const values = await form.validateFields()

      // update template
      await updateTemplateMutation({
        variables: {
          templateId,
          body: {
            ...template,
            ...previewTemplate,
            ...values,
            locationGalleryMapId:
              locationGalleryMapId === -1 ? null : locationGalleryMapId,
          },
        },
      })

      if (
        !locKey ||
        !photoUrl ||
        locationGalleryMapId === -1 ||
        !isChooseFileFromLibrary
      )
        return

      await updateLocationGalleryMappingMass({
        variables: {
          body: {
            coverKey: user.coverKey,
            locationGalleryMapId,
            locKeys: [locKey],
            frontPhotoPublicUrl: photoUrl,
            backPhotoPublicUrl: photoUrl,
          },
        },
      })

      refetch()
    } catch (e) {
      console.error(e)
      addToast(e.message || "Something went wrong", {
        appearance: "error",
        autoDismiss: true,
      })
      throw e
    }
  }

  // show loader
  if (getTemplateResp.loading || !previewTemplate)
    return <Loading withOverlay={true} />

  // selected template
  const selectedTemplate =
    previewTemplate &&
    postcards4x6.find(
      p => p.template.baseTemplateId === previewTemplate.baseTemplateId
    )

  const actionItemInsideDropdown = (action: "next" | "back") => {
    if (user && user.locations && locKey) {
      const index = user.locations.findIndex(item => item.locKey === locKey)
      if (action === "next") {
        if (index + 1 === user.locations.length) {
          setLocKey(user.locations[0].locKey)
        } else {
          setLocKey(user.locations[index + 1].locKey)
        }
      } else {
        if (index - 1 < 0) {
          setLocKey(user.locations[user.locations.length - 1].locKey)
        } else {
          setLocKey(user.locations[index - 1].locKey)
        }
      }
    }
  }

  return (
    <div css={[tw`flex bg-white flex-col`]}>
      {/* show loading during update */}
      {updateTemplateResp.loading && <Loading withOverlay={true} />}

      {isGalleryOpen && (
        <GalleryModal
          uploadType={UploadType.Background}
          onCancel={() => setIsGalleryOpen(false)}
          onSuccess={(fileUrl: string) => {
            setIsGalleryOpen(false)
            chooseFileFromLibrary(fileUrl)
          }}
        />
      )}

      {/* form */}
      <Form
        name="selectPhoto"
        form={form}
        initialValues={{
          frontPhotoScaleToFit: template && template.frontPhotoScaleToFit,
          backPhotoScaleToFit: template && template.backPhotoScaleToFit,
        }}
        onValuesChange={changedValues => {
          setPreviewTemplate &&
            setPreviewTemplate({
              ...(previewTemplate as Template),
              ...changedValues,
            })
        }}
      >
        <CustomContainer customCss={tw`flex flex-col`}>
          {/* campaign navigation */}
          <CampaignNavigation campaignId={campaignId} templateId={templateId} />

          {/* campaign orange info bar */}
          <InfoBarWithContext />

          {/* postcard editing */}
          <div tw="flex mt-4 items-end">
            {/* left container */}
            <LeftContainer>
              {locationGalleryMapId !== -1 && (
                <div
                  css={css`
                    margin-right: 90px;

                    ${tw`flex items-center mb-4`}
                  `}
                >
                  <Button
                    shape="circle"
                    css={tw`flex items-center justify-center text-black`}
                    onClick={() => actionItemInsideDropdown("back")}
                  >
                    <LeftOutlined />
                  </Button>

                  <Select
                    css={css`
                      min-width: 200px;

                      ${tw`mx-4 w-full`}

                      & .ant-select-selector {
                        ${tw`rounded-3xl!`}
                      }
                      & :hover {
                        & .ant-select-selector {
                          border-color: #d9d9d9 !important;
                        }
                      }
                    `}
                    size="large"
                    suffixIcon={<CaretDownOutlined css={tw`text-black`} />}
                    value={locKey}
                    onChange={value => setLocKey(value)}
                  >
                    {user &&
                      user.locations &&
                      user.locations.map(item => (
                        <Option key={item.locKey} value={item.locKey}>
                          {item.orgName}
                        </Option>
                      ))}
                  </Select>

                  <Button
                    shape="circle"
                    css={tw`flex items-center justify-center text-black`}
                    onClick={() => actionItemInsideDropdown("next")}
                  >
                    <RightOutlined />
                  </Button>
                </div>
              )}
              {previewTemplate && <PostcardPreview />}
            </LeftContainer>

            {/* right container */}
            <RightContainer
              heading={photoContent.heading}
              description={photoContent.description}
              customCss={css`
                max-height: 384px;
                overflow-y: auto;
              `}
            >
              <div tw="flex flex-col items-center pt-4 pb-2">
                <div css={[tw`text-xs text-black mb-4`]}>
                  <p css={[tw`font-bold text-sm`]}>
                    Add assets to your layout one of two ways.
                  </p>
                  <p css={[tw`mt-2`]}>
                    <span css={[tw`font-bold`]}>1) Manual:</span> Allows you to
                    manually add various content to this layout. Or
                  </p>
                  <p css={[tw`mt-2`]}>
                    <span css={[tw`font-bold`]}>2) Mapping:</span> Mapping a
                    pre-created asset library to this layout will save time by
                    automatically adding the correct corporate assets to the
                    right locations.
                  </p>
                </div>
                <div css={[tw`flex items-center w-full`]}>
                  <p css={tw`font-bold text-xs text-black`}>Manual</p>
                  <div
                    css={css`
                      ${tw`ml-2 w-full`}
                      height: 1px;
                      background-color: #979797;
                    `}
                  />
                </div>
                <div css={tw`w-full flex items-center mb-4`}>
                  <div
                    css={css`
                      ${tw`w-1/2`}
                    `}
                  >
                    <CustomButton
                      color={ButtonColors.gray}
                      onClick={() => setIsGalleryOpen(true)}
                      css={tw`mt-4`}
                    >
                      {photoContent.chooseLibrary}
                    </CustomButton>
                    <div tw="flex flex-col items-center mt-4">
                      <div
                        ref={draggerRef}
                        css={[
                          tw`w-full`,
                          css`
                            & .ant-upload-drag {
                              background-color: #fff !important;
                            }
                          `,
                        ]}
                      >
                        {/* file upload ui */}
                        <FileUpload
                          uploadType={UploadType.Background}
                          setLogoFile={chooseFileFromLibrary}
                          filesAllowed={[FileFormat.jpg]}
                        />

                        <div tw="flex items-center justify-between mt-2">
                          {isFront ? (
                            <Form.Item
                              name="frontPhotoScaleToFit"
                              noStyle
                              valuePropName="checked"
                            >
                              <Checkbox
                                css={css`
                                  & .ant-checkbox-checked .ant-checkbox-inner {
                                    background-color: #000 !important;
                                  }
                                `}
                              >
                                <span tw="text-2xs text-black">
                                  {photoContent.scaleToFit}
                                </span>
                              </Checkbox>
                            </Form.Item>
                          ) : (
                            <Form.Item
                              name="backPhotoScaleToFit"
                              noStyle
                              valuePropName="checked"
                            >
                              <Checkbox
                                css={css`
                                  & .ant-checkbox-checked .ant-checkbox-inner {
                                    background-color: #000 !important;
                                  }
                                `}
                              >
                                <span tw="text-2xs text-black">
                                  {photoContent.scaleToFit}
                                </span>
                              </Checkbox>
                            </Form.Item>
                          )}

                          <CustomButton
                            color={ButtonColors.gray}
                            customCss={tw`rounded-none! px-4! py-1!`}
                            onClick={() => {
                              if (draggerRef && draggerRef.current) {
                                const el = draggerRef.current.querySelector(
                                  ".ant-upload.ant-upload-btn"
                                ) as HTMLElement
                                if (el) {
                                  el.click()
                                }
                              }
                            }}
                          >
                            {fileContent.browse}
                          </CustomButton>
                        </div>
                      </div>
                    </div>

                    <div tw="flex flex-col items-center text-2xs justify-around text-black mt-4">
                      <div tw="flex">
                        <Bold>{fileContent.maxFileSizeLabel}</Bold>:{" "}
                        {fileContent.maxFileSizeValue}
                      </div>
                      <div tw="flex">
                        <Bold>{fileContent.acceptableFormats}</Bold>: .JPG Only
                      </div>
                    </div>
                  </div>
                  <div
                    css={css`
                      ${tw`w-1/2`}
                    `}
                  >
                    {/* <p css={tw`text-center mb-4`}>Asset Positioning</p> */}
                  </div>
                </div>
                <div css={[tw`flex items-center w-full`]}>
                  <p css={tw`font-bold text-xs text-black`}>Mapping</p>
                  <div
                    css={css`
                      ${tw`ml-2 w-full`}
                      height: 1px;
                      background-color: #979797;
                    `}
                  />
                </div>
                <div css={tw`flex text-black items-center w-full my-4`}>
                  <p css={[tw`font-bold text-xs mr-2`]}>Mapped Data Applied:</p>

                  <InfoCircleOutlined />
                </div>
                <div
                  css={tw`flex w-full items-center text-black text-xs justify-between`}
                >
                  <Select
                    css={css`
                      width: 50%;

                      & .ant-select-selector {
                        ${tw`rounded-3xl!`}
                      }
                      & :hover {
                        & .ant-select-selector {
                          border-color: #d9d9d9 !important;
                        }
                      }
                    `}
                    disabled
                    size="large"
                    suffixIcon={<CaretDownOutlined css={tw`text-black`} />}
                    value={locationGalleryMapId}
                    onChange={value => {
                      setLocationGalleryMapId(value)
                    }}
                  >
                    {getLocationGalleryMaps().map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                  <p
                    css={[
                      css`
                        ${tw`font-bold cursor-pointer`}

                        color: #0040d8;
                      `,
                    ]}
                    onClick={() => {
                      navigate("/mapping")
                    }}
                  >
                    Go To Asset Mapping &#62;
                  </p>
                </div>
              </div>
            </RightContainer>
          </div>
        </CustomContainer>

        {/* action bar */}
        <ActionBar
          withBoundaries={true}
          rightContainerCss={tw`w-4/12!`}
          backBtnText={
            previewTemplate.baseTemplateId === BaseTemplates.SemiCustom1
              ? ""
              : "BACK"
          }
          backBtnOnClick={() =>
            campaignId &&
            templateId &&
            selectedTemplate &&
            navigate(
              createUrl(
                navigationRoutes[
                  selectedTemplate.routes[PageOptions.PHOTO].prev as PageOptions
                ],
                {
                  campaignId,
                  templateId,
                }
              )
            )
          }
          saveBtnOnClick={async () => {
            await onSubmit()
          }}
          nextBtnOnClick={async () => {
            await onSubmit()

            campaignId &&
              templateId &&
              selectedTemplate &&
              navigate(
                createUrl(
                  navigationRoutes[
                    selectedTemplate.routes[PageOptions.PHOTO]
                      .next as PageOptions
                  ],
                  {
                    campaignId,
                    templateId,
                  }
                )
              )
          }}
        />
      </Form>
    </div>
  )
}

export default SelectPhoto
