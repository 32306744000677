import React from "react"

function toDataUrl(url: string, callback: any) {
  const xhr = new XMLHttpRequest()
  xhr.onload = function () {
    const reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open("GET", url)
  xhr.responseType = "blob"
  xhr.send()
}

function getBase64(file: File, callback: any) {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(file)
}

const useImgBase64 = (file?: File | string): string | null => {
  // image url
  const [imageUrl, setImageUrl] = React.useState<string | null>(null)

  // get image url from file
  React.useEffect(() => {
    if (typeof file === "string") {
      toDataUrl(file, (imgUrl: string) => setImageUrl(imgUrl))
    } else if (typeof file === "object") {
      getBase64(file, (imgUrl: string) => setImageUrl(imgUrl))
    }
  }, [])

  return imageUrl
}

export default useImgBase64
