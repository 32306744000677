import React from "react"
import tw, { css } from "twin.macro"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"

import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomContainer from "../customContainer"

interface ActionBarProps {
  backBtnText?: string
  backBtnOnClick?: () => void
  saveBtnText?: string
  saveBtnOnClick: () => void
  nextBtnText?: string
  nextBtnOnClick: () => void
  withBoundaries?: boolean
  leftContainerCss?: any
  rightContainerCss?: any
}

const ActionBar = ({
  backBtnText = "BACK",
  backBtnOnClick = () => null,
  saveBtnText = "SAVE",
  saveBtnOnClick,
  nextBtnText = "NEXT",
  nextBtnOnClick,
  withBoundaries = false,
  leftContainerCss = "",
  rightContainerCss = "",
}: ActionBarProps): React.ReactElement | null => {
  return (
    <CustomContainer customCss={tw`flex flex-row border-t border-gray-400`}>
      <div css={[tw`flex w-7/12 py-4`, leftContainerCss]}>
        {backBtnText && (
          <CustomButton
            color={ButtonColors.white}
            customCss={[tw`font-bold`]}
            onClick={() => backBtnOnClick()}
          >
            <LeftOutlined tw="mr-2" />
            {backBtnText}
          </CustomButton>
        )}
      </div>

      <div
        css={[
          tw`flex w-5/12 py-4`,
          rightContainerCss,
          withBoundaries ? tw`border-l border-r border-gray-400` : "",
          withBoundaries
            ? css`
                justify-content: space-evenly;
              `
            : tw`justify-end`,
        ]}
      >
        <CustomButton
          color={ButtonColors.blue}
          customCss={tw`mr-4`}
          onClick={() => saveBtnOnClick()}
        >
          {saveBtnText}
        </CustomButton>
        <CustomButton
          color={ButtonColors.orange}
          customCss={tw``}
          onClick={() => nextBtnOnClick()}
        >
          {nextBtnText} <RightOutlined tw="ml-2" />
        </CustomButton>
      </div>
    </CustomContainer>
  )
}

export default ActionBar
