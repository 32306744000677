import React from "react"
import tw, { css } from "twin.macro"

import PostcardPreviewContext from "../previewContext"
import { cmyk2hex, string2cmyk } from "@src/utils/color"

const CustomTemplate1 = (): React.ReactElement | null => {
  const { template, isFront } = React.useContext(PostcardPreviewContext)

  if (!template) {
    return null
  }

  return isFront ? (
    <div
      css={[
        tw`relative bg-center w-full h-full bg-no-repeat`,
        template.frontPhotoScaleToFit ? tw`bg-contain` : tw`bg-cover`,
        css`
          ${template.frontPhotoPublicUrl
            ? `background-image: url(${template.frontPhotoPublicUrl})`
            : ""};
        `,
      ]}
    />
  ) : (
    <div
      css={[
        tw`flex w-full h-full bg-center bg-no-repeat justify-end items-end`,
        template.backPhotoScaleToFit ? tw`bg-contain` : tw`bg-cover`,
        css`
          ${template.backPhotoPublicUrl
            ? `background-image: url(${template.backPhotoPublicUrl})`
            : ""};
        `,
      ]}
    >
      <div
        css={[
          tw`flex px-2 items-center`,
          css`
            height: 62%;
            width: 68%;
            color: ${cmyk2hex(string2cmyk(template.colorCode4))};
            background-color: ${cmyk2hex(string2cmyk(template.colorCode1))};
          `,
        ]}
      >
        <div tw="flex w-1/2">
          <div
            css={[
              css`
                text-align: left;
                font-size: 7pt;
                line-height: 1.4em;
                font-weight: bold;
              `,
            ]}
          >
            {"<<PATIENT_FIRST_NAME>> <<PATIENT_LAST_NAME>>"}
            <br />
            {"<<PATIENT_ADDRESS_1>>"}
            <br />
            {"<<PATIENT_ADDRESS_2>>"}
          </div>
        </div>
        <div tw="flex w-1/2 justify-end self-start">
          <div
            css={[
              tw`flex border border-black mt-4 mr-4 p-2`,
              css`
                text-align: center;
                font-size: 7pt;
                line-height: 1em;
              `,
            ]}
          >
            PRESORTED
            <br />
            FIRST CLASS MAIN
            <br /> U.S. POSTAGE
            <br />
            PAID
            <br />
            PATIENTCARE
            <br />
            89510
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomTemplate1
