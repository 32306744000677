interface Font {
  label: string
  value: string
}

interface FontWeight {
  normal: string
  bold: string
  italics: string
  boldItalics: string
}

export const fontWeight: FontWeight = {
  normal: "Regular",
  bold: "Bold",
  italics: "Italic",
  boldItalics: "Bold Italic",
}

const fonts: Font[] = [
  {
    label: "NotoSans",
    value: "NotoSans",
  },
  {
    label: "Lato",
    value: "Lato",
  },
  {
    label: "OpenSans",
    value: "Open Sans",
  },
  {
    label: "PTSerif",
    value: "PT Serif",
  },
  {
    label: "Ubuntu",
    value: "Ubuntu",
  },
  {
    label: "Vollkorn",
    value: "Vollkorn",
  },
]

export default fonts
