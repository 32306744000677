import React from "react"
import { Tooltip } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons"
import tw from "twin.macro"

const CustomToolTip = ({
  title,
  customCss,
}: {
  title: string
  customCss?: any
}): React.ReactElement => (
  <Tooltip
    placement="topRight"
    title={title}
    overlayStyle={{
      fontSize: "0.8rem",
    }}
    arrowPointAtCenter
    css={[tw`pt-2`, customCss]}
  >
    <InfoCircleOutlined />
  </Tooltip>
)

export default CustomToolTip
