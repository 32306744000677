import React from "react"
import tw from "twin.macro"

const Bold = ({ children }: { children: any }): React.ReactElement => (
  <div
    css={[
      tw`
        font-bold
      `,
    ]}
  >
    {children}
  </div>
)

export default Bold
