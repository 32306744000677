import React from "react"
import tw, { css } from "twin.macro"

const Logo = ({
  img,
  scaleToFit,
}: {
  img?: string
  scaleToFit: boolean
}): React.ReactElement | null => {
  return (
    <div
      css={[
        tw`flex flex-grow border border-dashed items-center justify-center bg-contain bg-no-repeat bg-center text-center text-xs`,
        css`
          border-color: rgb(180, 191, 204);
          line-height: 1em;
        `,
        scaleToFit ? tw`bg-contain` : tw`bg-cover`,
        img
          ? [
              tw`border-none`,
              css`
                background-image: url(${img});
              `,
            ]
          : "",
      ]}
    >
      {!img && <>{"Practice Logo"}</>}
    </div>
  )
}

export default Logo
