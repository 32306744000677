import React from "react"
import { Upload } from "antd"
import { useToasts } from "react-toast-notifications"

import UploadIcon from "@src/assets/uploadIcon.svg"
import TagUploadingFileModal from "./tagUploadingFile"
import { UploadType } from "@src/queries/gallery"
import tw from "twin.macro"

const { Dragger } = Upload

export enum FileFormat {
  png = "image/png",
  jpg = "image/jpeg",
}

const FileUpload = ({
  uploadType,
  setLogoFile,
  filesAllowed,
}: {
  uploadType: UploadType
  setLogoFile: (fileUrl: string) => void
  filesAllowed: FileFormat[]
}): React.ReactElement => {
  // toast
  const { addToast } = useToasts()

  // file to upload
  const [fileToUpload, setFileToUpload] = React.useState<File | null>(null)

  const uploadProps = {
    accept: filesAllowed.join(","),
    beforeUpload: (file: File) => {
      const isAllowed = filesAllowed.includes(file.type as FileFormat)
      if (!isAllowed) {
        addToast("File format not supported!", {
          appearance: "error",
          autoDismiss: true,
        })
      }

      // // lower limit for size
      // const isGt45K = file.size / 1024 > 0
      // if (!isGt45K) {
      //   addToast("Image must be larger than 45KB!", { appearance: "error", autoDismiss: true, })
      // }

      // upper limit for size
      const isLt10M = file.size / (1024 * 1024 * 10) < 1
      if (!isLt10M) {
        addToast("Image must be smaller than 10MB!", {
          appearance: "error",
          autoDismiss: true,
        })
      }

      if (isAllowed && isLt10M) {
        setFileToUpload(file)
      }

      return false
    },
    fileList: [],
  }

  return (
    <>
      {/* show file upload modal */}
      {fileToUpload && (
        <TagUploadingFileModal
          uploadType={uploadType}
          file={fileToUpload}
          onCancel={() => {
            setFileToUpload(null)
          }}
          onSuccess={(fileUrl: string) => {
            setLogoFile(fileUrl)
            setFileToUpload(null)
          }}
        />
      )}

      <Dragger name="file" multiple={false} {...uploadProps}>
        <div css={tw`flex flex-col items-center w-full`}>
          <UploadIcon style={{ width: "3rem" }} />
          <p tw="text-2xs text-gray-800 w-9/12 mt-2!">
            Drag logo to upload or click anywhere in the box
          </p>
        </div>
      </Dragger>
    </>
  )
}

export default FileUpload
