import React from "react"
import tw, { css } from "twin.macro"

import PostcardPreviewContext from "../previewContext"
import EditableField from "../EditableField"
import { TextFields } from "@src/queries/template"
import CopyContext from "@src/context/copyContext"
import Logo from "../Logo"

const DynamicTemplate = ({
  clearField,
  editable,
  updateFormFields,
}: {
  editable: boolean
  clearField: (fieldName: string) => void
  updateFormFields: (updateFields: any) => void
}): React.ReactElement | null => {
  const { template, isFront } = React.useContext(PostcardPreviewContext)

  // copy context
  const { field, setField } = React.useContext(CopyContext)

  if (!template) {
    return null
  }

  return isFront ? (
    <div
    css={[
      tw`relative w-full h-full bg-center bg-no-repeat m-1`,
      template.frontPhotoScaleToFit ? tw`bg-contain` : tw`bg-cover`,
      css`
        ${template.frontPhotoPublicUrl
          ? `background-image: url(${template.frontPhotoPublicUrl})`
          : ""};
          height: 97%;
          width: 98.3%;
      `,
    ]}
    >
      <div
        css={[
          tw`flex flex-col`,
          css`
            width: 62.5%;
          `,
        ]}
      >
        <div
          css={[
            tw`mt-16 ml-6 py-2`,
            css`
            `,
          ]}
        >
          {/* message1 field */}
          <EditableField
            field={field}
            textField={TextFields.message1}
            clearField={clearField}
            template={template}
            editable={editable}
            setField={setField}
            updateFormFields={updateFormFields}
            customCss={[
              tw`w-full`,
              css`
                max-height: 5em;
                text-align: left !important;
              `,
            ]}
          />
        </div>
      </div>
      <div
        css={[
          tw`flex flex-col`,
          css`
            width: 80%;
          `,
        ]}
      >
        <div
          css={[
            tw`mb-4 ml-6 py-2`,
            css`
            `,
          ]}
        >
          {/* message2 field */}
          <EditableField
            field={field}
            textField={TextFields.message2}
            clearField={clearField}
            template={template}
            editable={editable}
            setField={setField}
            updateFormFields={updateFormFields}
            customCss={[
              tw`w-full`,
              css`
                max-height: 5em;
                text-align: left !important;
              `,
            ]}
          />
          {/* message3 field */}
          <EditableField
            field={field}
            textField={TextFields.message3}
            clearField={clearField}
            template={template}
            editable={editable}
            setField={setField}
            updateFormFields={updateFormFields}
            customCss={[
              tw`w-full`,
              css`
                max-height: 5em;
                text-align: left !important;
              `,
            ]}
          />
          {/* message4 field */}
          <EditableField
            field={field}
            textField={TextFields.message4}
            clearField={clearField}
            template={template}
            editable={editable}
            setField={setField}
            updateFormFields={updateFormFields}
            customCss={[
              tw`w-full`,
              css`
                max-height: 5em;
                text-align: left !important;
              `,
            ]}
          />
        </div>
      </div>
      <div
        css={[
          tw`flex flex-col ml-6`,
          css`
            width: 60%;
            height: 20%;
            color: black;
          `,
        ]}
      >
        <div css={tw`flex flex-grow w-full h-full`}>
            <Logo
              img={template.frontLogoPublicUrl}
              scaleToFit={template.frontLogoScaleToFit}
            />
          </div>
      </div>
      <div
        css={[
          tw`flex flex-col ml-6`,
          css`
            width: 60%;
            height: 10%;
          `,
        ]}
      >
        <div
          css={[
            tw``,
            css`
            `,
          ]}
        >
          {/* message5 field */}
          <EditableField
              field={field}
              textField={TextFields.message5}
              clearField={clearField}
              template={template}
              editable={editable}
              setField={setField}
              updateFormFields={updateFormFields}
              customCss={css`
                line-height: 1.2em !important;
                text-align: left !important;
              `}
            />
        </div>
      </div>
    </div>
  ) : (
    <div
    css={[
      tw`relative w-full h-full bg-center bg-no-repeat m-1`,
      template.backPhotoScaleToFit ? tw`bg-contain` : tw`bg-cover`,
      css`
        ${template.backPhotoPublicUrl
          ? `background-image: url(${template.backPhotoPublicUrl})`
          : ""};
          height: 97%;
          width: 98.3%;
      `,
    ]}
    >
      <div tw="flex flex-col w-full h-full">
      <div
        css={[
          tw`flex p-2`,
          css`
            height: 38%;
            color: black;
          `,
        ]}
      >
        <div
          css={[
            tw`flex flex-col`,
            css`
              width: 100%;
            `,
          ]}
        >
          <div css={tw`flex flex-grow w-5/12`}>
            <Logo
              img={template.backLogoPublicUrl}
              scaleToFit={template.backLogoScaleToFit}
            />
          </div>
          <div css={tw`mt-2 pb-1`}>
            {/* message10 field */}
            <EditableField
              field={field}
              textField={TextFields.message10}
              clearField={clearField}
              template={template}
              editable={editable}
              setField={setField}
              updateFormFields={updateFormFields}
              customCss={css`
                line-height: 1.2em !important;
                text-align: left !important;
              `}
            />
          </div>
        </div>
      </div>
      <div tw="flex flex-col">
      <div css={[tw`flex flex-grow`]}>
          <div
            css={[
              tw`flex`,
              css`
              width: 100%;
              `,
            ]}
          >
              <div tw="flex w-full justify-end">
                <div
                  css={[
                    tw`flex border border-black mr-8 p-2`,
                    css`
                      text-align: center;
                      color: black;
                      font-size: 6pt;
                      line-height: 1em;
                    `,
                  ]}
                >
                  PRESORTED
                  <br />
                  FIRST CLASS MAIN
                  <br /> U.S. POSTAGE
                  <br />
                  PAID
                  <br />
                  PATIENTCARE
                  <br />
                  89510
                </div>
              </div>
            </div>
          </div>
      </div>
      <div tw="flex flex-col w-full">
        <div css={[tw`flex flex-grow`]}>
          <div
            css={[
              tw`flex px-2 items-center`,
              css`
              width: 100%;
              `,
            ]}
          >
            <div tw="flex w-full justify-center">
              <div
                css={[
                  tw`flex px-2 ml-32 mt-8`,
                  css`
                    text-align: left;
                    color: black;
                    font-size: 8pt;
                    line-height: 1.4em;
                  `,
                ]}
              >
                {/* message9 field */}
          <EditableField
            field={field}
            textField={TextFields.message9}
            clearField={clearField}
            template={template}
            editable={editable}
            setField={setField}
            updateFormFields={updateFormFields}
            customCss={[
              tw`h-24 overflow-hidden`,
              css`
                line-height: 1.7em !important;
                text-align: left !important;
              `,
            ]}
          />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    
  )
}

export default DynamicTemplate
